.dash-row {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}

.dash-row > * {
  margin-right: 1rem;
  flex: 1;
}

.barGraph {
  position: relative;
}

.bars,
.tools,
.labels {
  position: absolute;
  left: 0px;
  top: 0px;
}

.bars {
  z-index: 0;
}

.tools {
  z-index: 1;
}

.labels {
  z-index: 2;
}
