@import "../../../../typography.css";
@import "../../../../colours.css";

.default-slot-form {
  position: relative;
}

.default-slot-form .button-col,
.default-slot-form .button-col .btn {
  display: block;
}

.slot-config-table td {
  padding: 0px 8px;
  vertical-align: inherit;
}

.default-slot-form-title {
  margin-left: 16px;
}

.slot-config-reset,
.slot-config-copy {
  cursor: pointer;
}

.slot-copy-to-disabled {
  color: var(--base-mid);
}

.slot-copy-to {
  cursor: pointer;
}

.slot-copy {
  width: 112px;
  border: 1px solid var(--base-mid);
  border-radius: 4px;
  background-color: var(--base-white);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  font: var(--small-type);
  color: var(--base-black);
  z-index: 100;
  position: absolute;
  padding: 8px;
  top: 27px;
  right: 0;
  left: -32px;
}

.slot-copy:before {
  content: "";
  position: absolute;
  top: -16px;
  left: 30px;
  z-index: 1;
  border: solid 8px transparent;
  border-bottom-color: var(--base-mid);
}

.slot-copy:after {
  content: "";
  position: absolute;
  top: -15px;
  left: 30px;
  z-index: 1;
  border: solid 8px transparent;
  border-bottom-color: var(--base-white);
}

.default-slot-form-hint {
  font: var(--small-type);
  color: var(--base-dark);
  margin: 16px 16px -8px 16px;
}

.slot-config-table .copy-slot {
  font-size: small;
  vertical-align: middle;
}

.copy-menu {
  position: relative;
}
