@import "../../typography.css";
@import "../../colours.css";

/* @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
{
  select option:disabled::-ms-value{
    color:yellow !important;
  }
} */

/* select[disabled='disabled']::-ms-value {
  // stuff for select works, but not for disabled option (works for enabled option though)
  color: #FFF;
} */

.select-box {
  position: relative;
}

select.select-box__select::-ms-expand {
  display: none;
}

.select-box__select {
  padding: 0.5em 26px 0.5em 10px;
  border-radius: 3px;
  border: 1px solid var(--base-mid);
  outline: 0;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.select-box--dark .select-box__select {
  background: #474d64;
  color: var(--base-white);
}

.select-box__select[disabled] {
  color: var(--base-black);
  background: var(--base-light);
}

.select-box--light .select-box__select:focus {
  border-color: var(--base-black);
}

.select-box__icon {
  position: absolute;
  right: 8px;
  top: 12px;
  font-size: 16px;
  pointer-events: none;
}

.select-box--dark .select-box__icon {
  color: var(--base-white);
}

.select-box__select--has-errors {
  border-color: var(--alert-error);
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .select-box__icon {
    top: 12px;
  }
}
