@import "../../typography.css";
@import "../../colours.css";

.time-picker {
  position: relative;
}

.time-picker__input {
  padding: 0.5em 10px 0.5em 10px;
  border: 1px solid var(--base-mid);
  border-radius: 3px;
  outline: 0;
  width: 100%;
}

.time-picker__icon {
  position: absolute;
  right: 8px;
  top: 8px;
  pointer-events: none;
  color: var(--brand-primary);
}
