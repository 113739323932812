@import "../../typography.css";
@import "../../colours.css";

.text-box {
  padding: 0.5em 10px 0.5em 10px;
  border: 1px solid var(--base-mid);
  border-radius: 3px;
  outline: 0;
  width: 100%;
  color: var(--base-black);
}

.text-box:focus {
  border-color: var(--base-black);
}
