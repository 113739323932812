@import "../typography.css";
@import "../colours.css";

.note-box {
  background: var(--base-mid-30);
  border-radius: 5px;
  margin-top: auto;
}

.note-box__header {
  background: var(--brand-secondary);
  color: var(--base-white);
  border-radius: 5px 5px 0 0;
  display: flex;
  align-items: center;
  font-weight: bold;
  padding: 0 0.5rem;
}

.note-box__body {
  padding: 0.5rem;
  max-width: 330px;
}

.data-row {
  display: flex;
  justify-content: space-between;
}

.data-row__heading {
  font-weight: bold;
  margin-right: 0.25rem;
}
