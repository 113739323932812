@import "../../../typography.css";
@import "../../../colours.css";

.splash-page {
  height: 100vh;
  background: var(--base-white);
  overflow-y: scroll;
}

.splash-page__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  max-width: 320px;
  margin: 0 auto;
}

.splash-page__heading {
  margin-top: 16px;
  font: var(--heading-type);
}

.splash-page__image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.splash-page__image img {
  filter: drop-shadow(5px 5px 5px black);
  max-width: 364px;
  margin: 32px;
}

.splash-page {
  display: flex;
}

.splash-page__image {
  height: auto;
  width: 50%;
}

.splash-page__content {
  justify-content: center;
}
