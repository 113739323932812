.datepicker__header {
  display: flex;
  align-items: center;
  padding: 1rem;
  padding-bottom: 0;
  cursor: pointer;
  max-width: 246px;
  margin: 0 auto;
}

.datepicker__header .material-icons {
  font-size: 18px;
}

.datepicker__heading {
  width: 16px;
  text-align: center;
  font-weight: bold;
  margin: 8px;
  margin-bottom: 0;
}
