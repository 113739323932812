@import "../../../../colours.css";

.dnd-table {
  background: transparent;
  border-collapse: separate !important;
  border-spacing: 0;
}

.dnd-table tbody {
  background: var(--base-mid);
}

.dnd-header .dnd-handle {
  border-left: 3px solid var(--brand-tertiary);
}

.dnd-row .dnd-handle {
  border-left: 3px solid var(--base-white);
}

.dnd-row {
  background: var(--base-white);
}

.dnd-row--dragging {
  box-shadow: 0 1px 3px var(--base-black);
  background: var(--base-white);
}

.dnd-row--dragging .dnd-handle {
  border-color: var(--base-white);
}

.dnd-row--changed .dnd-handle {
  border-color: var(--overdue-orange);
}
