@import "../../typography.css";
@import "../../colours.css";

.text-input {
  padding: 0.5em 10px 0.5em 10px;
  border: 1px solid var(--base-mid);
  border-radius: 3px;
  outline: 0;
  width: 100%;
  color: var(--base-black);
}

.text-input--dark {
  background-color: var(--base-dark);
  color: var(--base-white);
}

.text-input--has-errors {
  color: var(--alert-error);
  border-color: var(--alert-error);
}

.text-input[disabled] {
  color: var(--base-black);
  background: var(--base-light);
}

.text-input:focus {
  color: var(--base-black);
  border-color: var(--base-black);
}

.text-input--dark:focus {
  background-color: var(--base-dark);
  color: var(--base-white);
  border: 1px solid var(--base-white);
}
