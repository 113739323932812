.appt-info td {
  padding: 0 5px 0 0;
}

.appt-table tr {
  border-bottom: 1px solid #eee;
}

.appt-info tr {
  border-bottom: 0;
}
