@import "../../typography.css";
@import "../../colours.css";

.multi-select {
  position: relative;
  border: 1px solid var(--base-mid);
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  width: 288px;
  overflow: hidden;
  height: 42px;
}

.multi-select-open {
  position: relative;
  border: 1px solid var(--base-dark);
  padding: 8px;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  height: 42px;
}

.multi-select-list {
  position: absolute;
  z-index: 1000;
  background-color: var(--base-white);
  border: 1px solid var(--base-dark);
  border-radius: 0 0 4px 4px;
  padding: 0;
  margin: 0;
  top: 40px;
  left: -1px;
  width: 288px;
}

.multi-select-list li {
  padding: 8px;
  margin: 0;
  border-radius: 0;
  border: 1px solid var(--base-white);
  background-color: var(--base-white);
}

.multi-select-list li:hover {
  background-color: var(--base-light);
}

.multi-select-header {
  width: 270px;
  overflow: hidden;
  white-space: nowrap;
}

.multi-select-header:focus {
  outline: none;
}

.multi-select-header:after {
  font-family: "Material Icons";
  content: "arrow_drop_down";
  vertical-align: bottom;
  position: absolute;
  top: 8px;
  right: 0;
  font-size: 24px;
  background-color: var(--base-white);
  background: linear-gradient(
    90deg,
    transparent,
    var(--base-white) 12%,
    var(--base-white)
  );
  /* IE11 Support */
  font-feature-settings: "liga";
}

.multi-select-list li:last-child {
  border-radius: 0 0 4px 4px;
}

.selected-item {
  font-weight: bold;
}

.selected-item:after {
  font-family: "Material Icons";
  content: "checked";
  vertical-align: bottom;
  margin-left: 8px;
  color: var(--alert-success);
  font-weight: bold;
  font-size: 20px;
  /* IE11 Support */
  font-feature-settings: "liga";
}

.multi-select__icon {
  position: absolute;
  right: 8px;
  top: 16px;
  font-size: 16px;
  pointer-events: none;
  color: var(--base-black);
}
