@import "../typography.css";
@import "../colours.css";

.round-btn-fixed {
  position: fixed;
  border: none;
  border-radius: 50%;
  color: var(--base-white);
  cursor: pointer;
  font: var(--content-type);
  font-weight: bold;
  height: 48px;
  width: 48px;
  align-items: center;
  display: flex;
  justify-content: center;
  right: 16px;
  bottom: 16px;
  cursor: pointer;
  background-color: var(--brand-primary);
}

.round-btn-absolute {
  position: absolute;
  border: none;
  border-radius: 50%;
  color: var(--base-white);
  cursor: pointer;
  font: var(--content-type);
  font-weight: bold;
  height: 48px;
  width: 48px;
  align-items: center;
  display: flex;
  justify-content: center;
  right: -24px;
  top: -24px;
  cursor: pointer;
  background-color: var(--brand-primary);
}

.round-btn-hover {
  position: fixed;
  border: none;
  border-radius: 50%;
  color: var(--base-white);
  cursor: pointer;
  font: var(--content-type);
  font-weight: bold;
  height: 56px;
  width: 56px;
  align-items: center;
  display: flex;
  justify-content: center;
  right: 36px;
  bottom: 32px;
  cursor: pointer;
  background-color: var(--brand-primary);
}

.round-btn-hover:hover {
  border: 1px solid black;
}

.hoverMenu {
  position: fixed;
  right: 48px;
  bottom: 100px;
}
