@import "../colours.css";
@import "../typography.css";

.app-wrapper {
  display: flex;
  flex-direction: column;
  width: calc(100% - 80px);
}

.nav-wrapper {
  display: flex;
  overflow: auto;
  height: 100%;
}

.inner-nav {
  display: flex;
  padding-left: 8px;
  padding-right: 8px;
  background: var(--header);
}

.inner-nav a,
.secondary-nav a,
.inner-nav .check-box {
  color: white;
}

.inner-nav .check-box__label {
  font: var(--small-type);
}

.inner-nav__left {
  margin-right: auto;
}

.inner-nav-list {
  display: flex;
  align-items: center;
  height: 80px;
  margin-top: 0;
  margin-bottom: 0;
}

.inner-nav-list__item {
  display: flex;
  flex-direction: column;
  margin-right: 64px;
  cursor: pointer;
  white-space: nowrap;
}

.inner-nav-list__item:last-child {
  margin-right: 0;
}

.inner-nav-list--tight .inner-nav-list__item {
  margin-right: 12px;
}

.inner-nav-list__item .form-control {
  margin-top: 10px;
}

.inner-nav-list__item--selected {
  font-weight: bold;
}

.inner-nav-list__item--selected .selected-lozenge {
  opacity: 1;
}

.inner-nav-list__item:hover .selected-lozenge {
  opacity: 1;
}

.secondary-nav__list {
  display: flex;
  margin: 0;
  padding: 0;
  padding-left: 48px;
  height: 60px;
  background: var(--brand-tertiary);
  align-items: center;
  color: white;
}

.menu-selected-date {
  color: white;
  cursor: pointer;
}

.menu-date-picker {
  position: absolute;
  color: white;
  top: 80px;
  right: 0px;
  z-index: 20;
  border: 1px solid var(--header);
  border-radius: 4px;
  padding: 8px;
  color: black;
  background: var(--base-white);
}

.search-input {
  padding: 8px;
  border-radius: 4px;
  height: 42px;
  border: 1px solid var(--base-mid);
  font: var(--small-type);
  color: #fff;
  background: none;
  outline: none;
  min-width: 300px;
}

.selected-lozenge {
  background: linear-gradient(180deg, #13b8e6 0%, #2f6cb4 100%);
  height: 5px;
  position: relative;
  top: 21px;
  border-radius: 2px 2px 0 0;
  opacity: 0;
  transition: opacity 1s;
}

.secondary-nav .selected-lozenge {
  top: 3px;
  background: white;
  height: 2px;
}

.secondary-nav .inner-nav-list__item--selected {
  font-weight: normal;
}
