@import "../../typography.css";
@import "../../colours.css";

.check-box {
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  word-break: normal;
  margin-left: 16px;
  line-height: 24px;
  vertical-align: middle;
}

input[type="checkbox"] {
  display: none;
}

.check-box__icon {
  padding-right: 14px;
  display: inline-block;
  color: var(--base-mid);
  font-size: 16px;
  vertical-align: middle;
  border: 1px solid var(--base-mid);
  height: 16px;
  width: 16px;
  margin-bottom: 2px;
  margin-right: 8px;
  color: var(--brand-primary);
}

.check-box__icon--checked {
  border-color: var(--brand-primary);
}

.not-a-real-selector {
  font-family: "Material Icons";
  padding: 0;
  content: "check";
  margin-right: 8px;
  color: var(--brand-primary);
  font-size: 14px;
  vertical-align: top;
  border: 1px solid var(--brand-primary);
  display: inline;
  max-height: 16px;
}
