@import "../../../../colours.css";
@import "../../../../typography.css";

.user-details-form {
  margin-top: 16px;
}

.user-details-title {
  font: var(--content-type);
}

.danger-zone {
  border: 1px solid #ddd;
  padding: 12px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 1.4em;
}

.danger-zone .caption {
  font-size: 14px;
  font-weight: 900;
  margin: 0 0 6px 0;
}

.danger-zone .cta {
  margin-top: 12px;
}

.flex-start {
  align-items: flex-start;
}