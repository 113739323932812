@import "../../../../typography.css";
@import "../../../../colours.css";

.slot-config-table .select-box__select {
  width: 100%;
  padding: 0 0 0 8px;
  color: var(--base-dark);
}

.slot-config-table .select-box__icon {
  top: 8px;
  color: var(--base-black);
}

.slot-config-table .form-control {
  display: inline-block;
  margin-left: 8px;
  width: 72px;
}

.date-picker-modal {
  position: absolute;
  z-index: 1000;
  border: 2px solid var(--base-dark);
  background-color: var(--base-white);
  border-radius: 4px;
  left: 16px;
  width: 278px;
}

.time-slot {
  width: 155px;
}

.slot-override {
  background-color: var(--base-grey);
  color: var(--base-white);
}

.slot-override:after {
  font-family: "Material Icons";
  content: "close";
  color: var(--base-grey);
  background-color: var(--base-white);
  border-radius: 50%;
  font-size: 16px;
  margin-left: 8px;
  padding-left: 1px;
  cursor: pointer;
}

.slot-date-select {
  width: 30%;
  display: inline-block;
  margin-left: 16px;
  margin-right: 16px;
}

.slot-clear-button {
  margin-left: 164px;
  display: inline-block;
}

.slot-config-form {
  position: relative;
}

.slot-config-form .button-col,
.slot-config-form .button-col .btn {
  display: block;
}

.slot-config-form-title {
  margin: 16px;
}

.slot-config-form-hint {
  font: var(--small-type);
  color: var(--base-mid);
  margin: -8px 16px 16px 16px;
}
