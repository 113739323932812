@import "./colours.css";
@import "./typography.css";
@import url(//fonts.googleapis.com/icon?family=Material + Icons|Material + Icons + Outlined|Material + Icons + Two + Tone|Material + Icons + Round|Material + Icons + Sharp);

body {
  margin: 0;
  padding: 0;
  font: var(--content-type);
  color: var(--base-black);
  background: var(--base-light);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

a {
  text-decoration: none;
  color: var(--base-black);
}

p {
  margin: 0;
  padding: 0;
}

table {
  font: var(--small-type);
  margin: 8px;
  width: calc(100% - 16px);
}

tr.table-row--selected {
  background: var(--base-mid);
}

tr.table-row--disabled {
  background: var(--alert-error-10);
}

tbody {
  background: var(--base-white);
}

td {
  padding: 4px 12px 4px 12px;
  border-bottom: 1px solid var(--base-light);
}

thead tr {
  color: var(--base-white);
  height: 40px;
}

th {
  text-align: left;
  font: var(--small-type);
  font-weight: bold;
  padding: 0 16px;
  background-color: var(--brand-tertiary);
  white-space: nowrap;
}

th:first-child {
  border-radius: 6px 0 0 6px;
}

th:last-child {
  border-radius: 0 6px 6px 0;
}

/* Typography helpers */
.center {
  text-align: center;
}

.heading {
  font: var(--heading-type);
}

.subheading {
  font: var(--subheading-type);
}

.small-type {
  font: var(--small-type);
}

.thin {
  font-weight: 300;
}

.bold {
  font-weight: bold;
}

.flex {
  display: flex;
}

.horizontal-container {
  display: flex;
  margin-bottom: 1rem;
}

.horizontal-container > * {
  margin-left: 1rem;
}

.horizontal-container > *:first-child {
  margin-left: 0;
}

.error-message {
  color: var(--alert-error);
}

.booking-list-link {
  cursor: pointer;
}

.link {
  cursor: pointer;
}

.booking-list-table {
  margin: 16px 0;
}

booking-list--empty {
  margin: 16px;
}

.booking-list--overdue {
  background: var(--alert-error-10) !important;
}
