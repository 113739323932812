@import "../typography.css";
@import "../colours.css";

/* Containers */
.app {
  display: flex;
  flex-direction: row;
  height: 100vh;
}

.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  /* I'm not sure why but this fixes content disappearing! */
  overflow: hidden;
}

.app--offset .main {
  margin-left: 80px;
  width: 100%;
}

.main-body {
  display: flex;
  width: 100%;
  padding-bottom: 100px;
  align-items: flex-start;
}

.calendar-view__main-body {
  display: flex;
  height: calc(100vh - 80px);
  width: 100%;
}

.form-control {
  margin-bottom: 10px;
  font: var(--small-type);
}

.button-row {
  display: flex;
  flex-direction: row;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.button-row button {
  flex-grow: 1;
}

.button-row:first-child {
  /* Remove margin from top-most button rows */
  margin-top: 0;
}

.button-row button:first-child:not(:only-child) {
  /* First button */
  margin-right: 3px;
}

.button-row button:last-child:not(:only-child) {
  /* Last button */
  margin-left: 3px;
}

.button-row button:not(:first-child):not(:last-child) {
  /* Buttons between first and last */
  margin-right: 3px;
  margin-left: 3px;
}

.admin-header {
  background-color: var(--brand-primary);
  color: var(--base-white);
  height: 100px;
  top: 0;
  padding-left: 16px;
  width: 100%;
  position: relative;
}

.admin-main {
  display: inline-block;
  overflow-y: auto;
}

.site-config {
  display: flex;
  height: 100vh;
  flex-direction: column;
}

.time-slot-main {
  display: flex;
}

.list-view-main {
  display: flex;
  overflow: auto;
}

.admin-panel {
  display: flex;
  height: 100vh;
  overflow: auto;
}

.admin-sidebar-title {
  margin-top: 96px;
  padding-left: 16px;
  position: relative;
}

.admin-panel-sidebar {
  background: var(--brand-tertiary);
  color: var(--base-white);
  border-right: 1px solid var(--base-mid);
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 314px;
}

.admin-sidebar-footer {
  bottom: 0;
  left: 0;
  position: absolute;
  cursor: pointer;
  padding: 1rem;
  background: var(--brand-tertiary);
  width: 100%;
}

.form-columns {
  display: flex;
}

.form-right-column {
  margin-left: 104px;
}

.content-block {
  background: white;
  border-radius: 20px;
  padding: 1rem 3rem;
}
