@import "../../../../typography.css";
@import "../../../../colours.css";

.list-view-body {
  width: 100%;
}

.schedule-table {
  min-height: 50px;
}

.in-motion {
  user-select: "none";
  padding: 4px;
  margin: 8px;
  background: var(--base-white);
}

.droppable {
  background: var(--droppable);
  padding: 8px;
}

.list-view {
  margin-left: 16px;
}

.list-view h3,
.list-view h5 {
  margin-left: 8px;
}

.priority-booking {
  background-color: rgba(255, 255, 10, 0.25);
}
