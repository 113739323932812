.toast-container {
  position: fixed;
  top: 1rem;
  right: 1rem;
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

.toast-container > .alert {
  margin-bottom: 1rem;
}
