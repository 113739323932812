@import "../../../../typography.css";
@import "../../../../colours.css";

.admin-main {
  padding: 16px;
  font: var(--small-type);
}
.sub-site {
  padding-left: 16px;
}

.icon-button {
  font-size: 16px;
  color: var(--base-dark);
  cursor: pointer;
}

.admin-form {
  width: 50%;
  display: flex;
}
.admin-form .form-control {
  vertical-align: top;
  padding: 20px;
}

.admin-form .button-row {
  margin: 0.75rem 0.75rem 0.75rem 0;
}

.slot-config-table {
  margin-left: 16px;
}
.slot-config-table .form-control {
  padding: 8px 0 0 0;
}
.slot-config-table td {
  border-bottom: 2px solid var(--base-light);
}

.slot-config-table .material-icons {
  vertical-align: middle;
}

.copy-menu {
  cursor: pointer;
}

.copy-clear {
  display: flex;
  flex-flow: wrap;
  padding-bottom: 8px;
}

.copy-functions,
.clear-functions {
  flex: 0 0 50%;
}

.site-details-form .button-col,
.site-details-form .button-col .btn {
  display: block;
}

.stand-level-table {
  width: 100%;
}

.stand-level-table .form-control {
  padding: 0;
}

.stand-level-table td {
  white-space: nowrap;
}

.stand-level-table .site-label {
  padding-top: 16px;
}

.booking-receipt-table-header-end {
  text-align: end;
}

.booking-receipt-table-row-end {
  text-align: end;
  padding-right: 16px;
  padding-top: 10px;
}

.booking-receipt-add-receipt {
  text-align: end;
  padding-top: 8px;
  padding-right: 8px;
}

.booking-receipt-table-row-switch {
  padding-left: 16px;
  padding-top: 14px;
}

.booking-receipt-table-input-row {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.booking-receipt-table-input {
  padding-left: 8px;
  width: 45%;
}
