@import "../../typography.css";
@import "../../colours.css";

.datepicker__day {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 2px;
  padding: 5px;
  border: 1px solid transparent;
  box-sizing: content-box;
}

.datepicker__day:hover:not(.datepicker__day--selected) {
  background-color: var(--base-mid);
  border-color: var(--base-mid);
}

.datepicker__day--today {
  border-color: var(--base-black);
}

.datepicker__day--selected {
  background-color: var(--brand-primary);
  color: var(--base-white);
  cursor: default;
  border-color: var(--brand-primary);
}

.datepicker__day--line-through {
  position: relative;
}

.datepicker__day--line-through:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 1px solid;
  transform: rotate(-45deg);
}

.datepicker__day--light {
  color: var(--base-mid);
}

.datepicker__day--light:hover {
  color: var(--base-white);
}

.datepicker__day--light.datepicker__day--line-through:before {
  border-color: var(--base-mid);
}

.datepicker__day--light.datepicker__day--today {
  border-color: transparent;
}

.datepicker__day .text {
  min-width: 1rem;
  text-align: center;
}
