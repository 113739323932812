@import "../../../../typography.css";
@import "../../../../colours.css";

.admin-form {
  width: 30%;
  display: flex;
}
.admin-form .form-control {
  vertical-align: top;
  padding: 20px;
}

.admin-form .button-row {
  margin: 0.75rem 0.75rem 0.75rem 0;
}

.slot-config-table {
  margin-left: 16px;
}
.slot-config-table .form-control {
  padding: 8px 0 0 0;
}
.slot-config-table td {
  border-bottom: 2px solid var(--base-light);
}

.slot-config-table .material-icons {
  vertical-align: middle;
}

.copy-menu {
  cursor: pointer;
}

.copy-clear {
  display: flex;
  flex-flow: wrap;
  padding-bottom: 8px;
}

.copy-functions,
.clear-functions {
  flex: 0 0 50%;
}

.site-details-form .button-col,
.site-details-form .button-col .btn {
  display: block;
}
