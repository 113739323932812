@import "../../../typography.css";
@import "../../../colours.css";

.form-field-error {
  font: var(--small-type);
  color: var(--alert-error);
}

.form-field-warning {
  font: var(--small-type);
  color: var(--overdue-orange-darker);
}

.focus-view .loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
