@import "../typography.css";
@import "../colours.css";

.notice-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--base-black);
  opacity: 0.3;
  z-index: 10;
}

.notice {
  padding: 16px;
  border: 2px solid;
  border-radius: 3px;
  width: 40%;
  background-color: white;
  font: var(--small-type);
  position: fixed;
  top: 40%;
  left: 30%;
  z-index: 100;
}

.notice--success {
  color: var(--alert-success);
  background: var(--alert-success-10);
}

.notice--error {
  color: var(--alert-error);
  background: var(--alert-error-10);
}

.notice__icon {
  font-size: 18px;
}

.notice__icon--dismiss {
  cursor: pointer;
}

.notice__message {
  margin: 0.5rem 0.5rem 16px 0.5rem;
}
