.application-table__header {
  text-align: center;
  width: 50%;
}

.application-table__td {
  padding: 24px 4px;
}

.application-table__message {
  color: red;
}
