@import "../typography.css";
@import "../colours.css";

.admin-panel__items {
  overflow-y: scroll;
  margin-bottom: 64px;
}

.admin-details {
  width: 100%;
  overflow-y: scroll;
}

.admin-details-title {
  margin-left: 62px;
  font: var(--heading-type);
}

.admin-details-location {
  margin-left: 62px;
  font: var(--subheading-type);
  margin-bottom: 24px;
}

.admin-details-main {
  background-color: var(--base-white);
  border-radius: 24px;
  margin-left: 32px;
  padding: 32px;
  width: 992px;
}

.admin-menu-item {
  display: inline-block;
  margin-right: 32px;
}

.admin-menu-item-title {
  font: var(--small-type);
}

.admin-menu-item-selected {
  display: inline-block;
  margin-right: 32px;
  border-bottom: 2px solid var(--brand-primary);
}
