@import "../typography.css";
@import "../colours.css";

.btn {
  outline: 0;
  padding: 12px;
  border: none;
  border-radius: 10px;
  color: var(--base-white);
  cursor: pointer;
  font: var(--content-type);
  font-weight: bold;
  min-width: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

input[type="file"]::file-selector-button {
  outline: 0;
  padding: 12px;
  margin-bottom: 6px;
  border: none;
  border-radius: 10px;
  color: var(--base-white);
  background-color: var(--brand-primary);
  cursor: pointer;
  font: var(--small-type);
  font-weight: bold;
  min-width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.btn--small {
  font: var(--small-type);
  font-weight: bold;
  min-width: 160px;
}

.btn--xsmall {
  font: var(--small-type);
  font-weight: bold;
  min-width: 60px;
}

.btn--tiny {
  font: var(--small-type);
  font-weight: bold;
  min-width: 60px;
  width: 100%;
  height: 2rem;
  padding: 0 0.25rem;
  border-radius: 5px;
  text-transform: uppercase;
}

.btn[disabled] {
  opacity: 0.5;
  cursor: auto;
}

.btn--primary {
  background-color: var(--brand-primary);
}

.btn--secondary {
  background-color: var(--brand-secondary);
}

.btn--white {
  background-color: var(--base-white);
  color: var(--base-black);
  border: 1px solid var(--base-mid);
}

.btn--dark {
  background: var(--base-dark);
  color: var(--base-white);
}

.btn--red {
  background: var(--overdue-red-darker);
  color: var(--base-white);
}

.btn--secondary-30 {
  background: var(--brand-secondary-30);
  color: var(--base-black);
  border: 1px solid var(--base-mid);
}

.btn--cancel {
  background-color: var(--alert-error);
}
