@import "../typography.css";
@import "../colours.css";
.admin-menu-item {
  display: inline-block;
  margin-right: 32px;
  cursor: pointer;
}

.admin-menu-item-title {
  font: var(--small-type);
}

.admin-menu-item-selected {
  display: inline-block;
  margin-right: 32px;
  border-bottom: 2px solid var(--brand-primary);
  cursor: pointer;
}
