@import "../../typography.css";
@import "../../colours.css";

.password-input-container {
  position: relative;
}
.password-input {
  padding: 0.5em 10px 0.5em 10px;
  border: 1px solid var(--base-mid);
  border-radius: 3px;
  outline: 0;
  width: 100%;
  color: var(--base-black);
}

.password-input--has-errors {
  color: var(--alert-error);
  border-color: var(--alert-error);
}

.password-input[disabled] {
  color: var(--base-black);
  background: var(--base-light);
}

.password-input:focus {
  color: var(--base-black);
  border-color: var(--base-black);
}

.password-input__icon {
  position: absolute;
  top: 8px;
  right: 8px;
  color: var(--base-dark);
}
