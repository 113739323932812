.day-table {
  margin: 0 auto;
  margin-top: 8px;
  max-width: 246px;
}

.day-table__row {
  display: flex;
  margin: 0 8px;
}

.day-table__row:last-child {
  margin-bottom: 8px;
}

.day-table__heading {
  width: 16px;
  text-align: center;
  font-weight: bold;
  margin: 8px;
}
