.month-picker {
  position: relative;
  margin-right: auto;
  display: flex;
  align-items: center;
}

.month-picker__hidden-select {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.month-picker__year {
  margin-right: auto;
}

.month-picker__month {
  font-weight: bold;
  margin-right: 0.25em;
}

.month-picker .material-icons {
  font-size: 14px;
  margin-left: 3px;
}
