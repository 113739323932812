@import "../../../typography.css";
@import "../../../colours.css";

.table-filter-bar {
  display: flex;
  margin: 0 8px;
}

.capsule {
  font: var(--small-type);
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--brand-primary-50);
  border-radius: 5px;
  padding: 0 0.5rem;
  border: 1px solid var(--brand-primary);
}

.capsule__title {
  font-weight: bold;
  margin-right: 0.5rem;
}

.table-filter-bar .capsule {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.table-filter-bar .btn {
  width: auto;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}

.table-filter-bar > .btn {
  width: auto;
  margin-left: auto;
  margin-right: 0;
  flex-shrink: 0;
}

.table-filter-bar > .flex {
  flex-wrap: wrap;
}
