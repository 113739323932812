@import "../colours.css";
@import "../typography.css";

.right-panel {
  display: none;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: var(--base-white);
  padding: 1em;
  border-left: 1px solid var(--base-mid);
  overflow-y: scroll;
  z-index: 100;
}

.right-panel--dark {
  background: var(--base-dark);
  border-left: 1px solid var(--base-black);
  color: var(--base-white);
}

.right-panel--expanded {
  display: inherit;
}

.right-panel__header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.right-panel__title {
  font: var(--content-type);
  font-weight: bold;
}

.right-panel__close {
  margin-left: auto;
  cursor: pointer;
}

@media (max-width: 1400px) {
  .right-panel {
    right: -80px;
  }
}
