@import "../../../../typography.css";
@import "../../../../colours.css";

.user-roles-table {
  margin-left: 16px;
}
.user-roles-table .form-control {
  padding: 8px 0 0 0;
}
.user-roles-table td {
  border: 1px solid var(--base-light);
}

.user-roles-table .material-icons {
  vertical-align: middle;
  font-size: 16px;
  cursor: pointer;
}

.tag {
  border: 1px solid var(--base-light);
  background-color: var(--base-light);
  margin: 2px 4px;
  border-radius: 4px;
  padding: 2px 4px;
  cursor: pointer;
}

.user-roles {
  margin: 8px;
  border-radius: 4px;
  max-width: 300px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.add-role {
  display: inline-block;
}

.save-role {
  position: absolute;
  top: 50%;
  right: -16px;
}

.current-tag {
  background-color: var(--alert-success-10);
}

.new-tag {
  background-color: var(--alert-error-10);
}

.multi-select-list > li {
  list-style: none;
}

.zones-dock-table .form-control {
  padding: 0px;
  margin-bottom: 0px;
}

.zones-dock-table {
  text-align: center;  
}

.dock-safety-device-table {
display: flex;
width:100%;
}