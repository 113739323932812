@import "../../typography.css";
@import "../../colours.css";

.full-screen-exit-hint {
  font: var(--small-type);
  color: var(--base-grey);
  margin: 4px 4px 4px 4px;
}

.full-screen-body {
  background: var(--flightboard-background);
  color: var(--base-white);
  height: 100%;
  padding: 3% 5% 2.5% 5%;
}

.flightboard-container {
  height: 100%;
}

.slick-slider {
  height: 100%;
}

.flightboard-table {
  width: 100%;
  margin: auto;
  background: transparent;
}

.flightboard-table tbody {
  background: transparent;
}

.flightboard-table thead tr th {
  font-family: pill-gothic-300mg, sans-serif;
  font-size: 34px;
  height: 5rem;
  background: var(--flightboard-header);
  text-align: left;
  vertical-align: middle;
  text-transform: uppercase;
}

.flightboard-table tbody td {
  font-family: pill-gothic-300mg, sans-serif;
  font-size: 35px;
  height: 6rem;
  text-align: left;
  vertical-align: middle;
  text-transform: uppercase;
}

.flightboard-table tbody tr:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.slick-dots li button:before {
  font-size: 1rem !important;
  color: var(--base-white) !important;
}

.flightboard-top-bar {
  height: 0.5rem;
  background: linear-gradient(
    90deg,
    var(--flightboard-1) 0%,
    var(--flightboard-2) 49.89%,
    var(--flightboard-3) 96.94%,
    var(--flightboard-4) 100%
  );
}

.flightboard-icon {
  width: 18%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}

.slick-slide * {
  outline: none !important;
}

.flightboard-status__arrived {
  color: var(--arrived);
}
.flightboard-status__trailer_attached {
  color: var(--trailer-attached);
}
.flightboard-status__inbound_in_progress {
  color: var(--inbound-in-progress);
}
.flightboard-status__outbound_in_progress {
  color: var(--outbound-in-progress);
}
.flightboard-status__ready_for_driver {
  color: var(--ready-for-driver);
}

.flightboard-flag img {
  margin: auto;
}
