@import "../../../../colours.css";
@import "../../../../typography.css";

.remove {
  color: var(--alert-error);
}

.resend,
.remove {
  cursor: pointer;
}
.invites-title {
  font: var(--content-type);
}
