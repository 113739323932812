.zone-table td {
  vertical-align: inherit;
}
.zone-table .manual {
  background-color: rgba(255, 255, 10, 0.25);
}

.zone-table .material-icons {
  vertical-align: inherit;
}

.prev-action,
.next-action {
  cursor: pointer;
}

.prev-action-disabled,
.next-action-disabled {
  color: #ccc;
}
