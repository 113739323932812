.recover-account-form {
  margin-top: 16px;
  width: 100%;
}

.recover-account-form .btn--primary {
  margin-top: 24px;
}

.recover-account-form .btn--white {
  margin-top: 16px;
}

.recover-account-form__buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}
