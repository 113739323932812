@import "../../../../colours.css";

.quick-search-list {
  margin: 0;
  padding: 0;
  position: relative;
  border: 1px solid var(--base-mid);
  top: -11px;
  border-radius: 3px;
}

.quick-search-item {
  list-style: none;
  padding: 0 0.5rem;
}

.quick-search-item:hover {
  background: var(--base-mid);
}
