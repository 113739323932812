@import "../../../../typography.css";
@import "../../../../colours.css";

.list-view {
  margin-left: 16px;
}

.list-view h3,
.list-view h5 {
  margin-left: 8px;
}

.dnd-table {
  background: var(--base-mid);
  border-collapse: separate !important;
  border-spacing: 0;
}

.dnd-header .dnd-handle {
  border-left: 3px solid var(--brand-tertiary);
}

.dnd-row .dnd-handle {
  border-left: 3px solid var(--base-white);
}

.dnd-row {
  background: var(--base-white);
}

.dnd-row--dragging {
  box-shadow: 0 1px 3px var(--base-black);
  background: var(--base-white);
}

.dnd-row--dragging .dnd-handle {
  border-color: var(--base-white);
}

.dnd-row--changed .dnd-handle {
  border-color: var(--overdue-orange);
}
