@import "../../typography.css";
@import "../../colours.css";

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  vertical-align: top;
  margin-right: 8px;
}

/* The switch can be shown in a disabled state */
.switch-label[data-disabled="true"],
.switch-label[data-disabled="true"] .slider {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--base-black);
  transition: 0.4s;
  border-radius: 30px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 6px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--brand-primary);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--brand-primary);
}

input:checked + .slider:before {
  transform: translateX(20px);
}

