@import "../../../colours.css";

.settings-title {
  margin-left: 1rem;
}

.setting-tile-list {
  display: flex;
  flex-wrap: wrap;
  width: 93vw;
}

.setting-tile {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  background: var(--base-white);
  width: 12rem;
  height: 12rem;
}

.setting-tile a {
  text-align: center;
  text-decoration: none;
  color: var(--base-black);
}

.setting-tile i {
  display: block;
  font-size: 5rem;
}
