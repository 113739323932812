@import "../typography.css";
@import "../colours.css";

.alert {
  padding: 0.5rem 10px 0.5rem 10px;
  border: 1px solid;
  border-radius: 3px;
  width: 100%;
  font: var(--small-type);
  font-weight: bold;
  display: flex;
  align-items: center;
  opacity: 1;
  transition: opacity 1s;
}

.alert--fade-out {
  opacity: 0;
}

.alert--success {
  color: var(--alert-success);
  background: var(--alert-success-10);
}

.alert--error {
  color: var(--alert-error);
  background: var(--alert-error-10);
}

.alert--locked {
  color: var(--alert-error);
  background: var(--alert-error-10);
}

.alert--warning {
  color: var(--alert-warning);
  background: var(--alert-warning-10);
}

.alert__icon {
  font-size: 18px;
}

.alert__icon--dismiss {
  cursor: pointer;
}

.alert__message {
  margin-left: 0.5rem;
  margin-right: auto;
  white-space: pre-wrap;
}
