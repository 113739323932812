@import "../typography.css";
@import "../colours.css";

.datepicker {
  border: 1px solid var(--base-mid);
  border-width: 1px 0;
  font: var(--small-type);
  margin: 1em;
}

.datepicker--dark {
  color: var(--base-white);
  background: var(--brand-secondary);
}

.datepicker--dark .datepicker__day--today {
  border-color: var(--base-mid);
}
