@import "../../typography.css";
@import "../../colours.css";

.custom-control {
  position: relative;
  border: 1px solid var(--base-mid);
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  width: 288px;
  overflow: hidden;
  height: 42px;
}

.custom-control-open {
  position: relative;
  border: 1px solid var(--base-dark);
  padding: 8px;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  height: 42px;
}

.custom-control-list {
  position: absolute;
  z-index: 1;
  background-color: var(--base-white);
  border: 1px solid var(--base-dark);
  border-radius: 0 0 4px 4px;
  padding: 0;
  margin: 0;
  top: 40px;
  left: -1px;
  width: 288px;
}

.custom-control-header {
  width: 270px;
  overflow: hidden;
  white-space: nowrap;
}

.custom-control--dark .custom-control-header {
  color: var(--base-white);
}

.custom-control--dark.custom-control-open {
  border-color: var(--base-mid);
}

.custom-control--dark .custom-control-list {
  border-color: var(--base-mid);
  background: var(--brand-secondary);
}

.custom-control-header:focus {
  outline: none;
}

.custom-control-header .material-icons {
  position: absolute;
  top: 12px;
  right: 8px;
  font-size: medium;
}
