@import url(//fonts.googleapis.com/icon?family=Material + Icons|Material + Icons + Outlined|Material + Icons + Two + Tone|Material + Icons + Round|Material + Icons + Sharp);

body {
  margin: 0;
  padding: 0;
  font: 400 21px/32px "Source Sans Pro", sans-serif;
  color: #111;
  background: #f3f3f5;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px; /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  -webkit-font-feature-settings: "liga";
          font-feature-settings: "liga";
}a {
  text-decoration: none;
  color: #111;
}p {
  margin: 0;
  padding: 0;
}table {
  font: 400 15px/24px "Source Sans Pro", sans-serif;
  margin: 8px;
  width: calc(100% - 16px);
}tr.table-row--selected {
  background: #ccc;
}tr.table-row--disabled {
  background: #ffe8e7;
}tbody {
  background: #fff;
}td {
  padding: 4px 12px 4px 12px;
  border-bottom: 1px solid #f3f3f5;
}thead tr {
  color: #fff;
  height: 40px;
}th {
  text-align: left;
  font: 400 15px/24px "Source Sans Pro", sans-serif;
  font-weight: bold;
  padding: 0 16px;
  background-color: #0a132f;
  white-space: nowrap;
}th:first-child {
  border-radius: 6px 0 0 6px;
}th:last-child {
  border-radius: 0 6px 6px 0;
}/* Typography helpers */.center {
  text-align: center;
}.heading {
  font: 700 48px/56px "Source Sans Pro", sans-serif;
}.subheading {
  font: 700 36px/40px "Source Sans Pro", sans-serif;
}.small-type {
  font: 400 15px/24px "Source Sans Pro", sans-serif;
}.thin {
  font-weight: 300;
}.bold {
  font-weight: bold;
}.flex {
  display: flex;
}.horizontal-container {
  display: flex;
  margin-bottom: 1rem;
}.horizontal-container > * {
  margin-left: 1rem;
}.horizontal-container > *:first-child {
  margin-left: 0;
}.error-message {
  color: #e3170a;
}.booking-list-link {
  cursor: pointer;
}.link {
  cursor: pointer;
}.booking-list-table {
  margin: 16px 0;
}booking-list--empty {
  margin: 16px;
}.booking-list--overdue {
  background: #ffe8e7 !important;
}

.alert {
  padding: 0.5rem 10px 0.5rem 10px;
  border: 1px solid;
  border-radius: 3px;
  width: 100%;
  font: 400 15px/24px "Source Sans Pro", sans-serif;
  font-weight: bold;
  display: flex;
  align-items: center;
  opacity: 1;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}
.alert--fade-out {
  opacity: 0;
}
.alert--success {
  color: #71b340;
  background: #f1f7ec;
}
.alert--error {
  color: #e3170a;
  background: #ffe8e7;
}
.alert--locked {
  color: #e3170a;
  background: #ffe8e7;
}
.alert--warning {
  color: #de9121;
  background: #fff5e7;
}
.alert__icon {
  font-size: 18px;
}
.alert__icon--dismiss {
  cursor: pointer;
}
.alert__message {
  margin-left: 0.5rem;
  margin-right: auto;
  white-space: pre-wrap;
}

.toast-container {
  position: fixed;
  top: 1rem;
  right: 1rem;
  display: flex;
  flex-direction: column;
  z-index: 1000;
}

.toast-container > .alert {
  margin-bottom: 1rem;
}

.btn {
  outline: 0;
  padding: 12px;
  border: none;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  font: 400 21px/32px "Source Sans Pro", sans-serif;
  font-weight: bold;
  min-width: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}
input[type="file"]::file-selector-button {
  outline: 0;
  padding: 12px;
  margin-bottom: 6px;
  border: none;
  border-radius: 10px;
  color: #fff;
  background-color: #4aa366;
  cursor: pointer;
  font: 400 15px/24px "Source Sans Pro", sans-serif;
  font-weight: bold;
  min-width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}
.btn--small {
  font: 400 15px/24px "Source Sans Pro", sans-serif;
  font-weight: bold;
  min-width: 160px;
}
.btn--xsmall {
  font: 400 15px/24px "Source Sans Pro", sans-serif;
  font-weight: bold;
  min-width: 60px;
}
.btn--tiny {
  font: 400 15px/24px "Source Sans Pro", sans-serif;
  font-weight: bold;
  min-width: 60px;
  width: 100%;
  height: 2rem;
  padding: 0 0.25rem;
  border-radius: 5px;
  text-transform: uppercase;
}
.btn[disabled] {
  opacity: 0.5;
  cursor: auto;
}
.btn--primary {
  background-color: #4aa366;
}
.btn--secondary {
  background-color: #40465b;
}
.btn--white {
  background-color: #fff;
  color: #111;
  border: 1px solid #ccc;
}
.btn--dark {
  background: #333;
  color: #fff;
}
.btn--red {
  background: #b53112;
  color: #fff;
}
.btn--secondary-30 {
  background: #d9dade;
  color: #111;
  border: 1px solid #ccc;
}
.btn--cancel {
  background-color: #e3170a;
}

.loader {
  position: relative;
  margin: 0 auto;
  width: 100px;
}
.loader:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.circular {
  -webkit-animation: rotate 2s linear infinite;
          animation: rotate 2s linear infinite;
  height: 100%;
  -webkit-transform-origin: center center;
          transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  -webkit-animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
          animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}
@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}
@-webkit-keyframes color {
  100% {
    stroke: #d62d20;
    stroke: #d62d20;
  }
  0% {
    stroke: #d62d20;
    stroke: #d62d20;
  }
  40% {
    stroke: #0057e7;
    stroke: #0057e7;
  }
  66% {
    stroke: #008744;
    stroke: #008744;
  }
  80% {
    stroke: #ffa700;
    stroke: #ffa700;
  }
  90% {
    stroke: #ffa700;
    stroke: #ffa700;
  }
}
@keyframes color {
  100% {
    stroke: #d62d20;
    stroke: #d62d20;
  }
  0% {
    stroke: #d62d20;
    stroke: #d62d20;
  }
  40% {
    stroke: #0057e7;
    stroke: #0057e7;
  }
  66% {
    stroke: #008744;
    stroke: #008744;
  }
  80% {
    stroke: #ffa700;
    stroke: #ffa700;
  }
  90% {
    stroke: #ffa700;
    stroke: #ffa700;
  }
}

.text-input {
  padding: 0.5em 10px 0.5em 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: 0;
  width: 100%;
  color: #111;
}
.text-input--dark {
  background-color: #333;
  color: #fff;
}
.text-input--has-errors {
  color: #e3170a;
  border-color: #e3170a;
}
.text-input[disabled] {
  color: #111;
  background: #f3f3f5;
}
.text-input:focus {
  color: #111;
  border-color: #111;
}
.text-input--dark:focus {
  background-color: #333;
  color: #fff;
  border: 1px solid #fff;
}

.splash-page {
  height: 100vh;
  background: #fff;
  overflow-y: scroll;
}
.splash-page__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  max-width: 320px;
  margin: 0 auto;
}
.splash-page__heading {
  margin-top: 16px;
  font: 700 48px/56px "Source Sans Pro", sans-serif;
}
.splash-page__image {
  display: flex;
  align-items: center;
  justify-content: center;
}
.splash-page__image img {
  -webkit-filter: drop-shadow(5px 5px 5px black);
          filter: drop-shadow(5px 5px 5px black);
  max-width: 364px;
  margin: 32px;
}
.splash-page {
  display: flex;
}
.splash-page__image {
  height: auto;
  width: 50%;
}
.splash-page__content {
  justify-content: center;
}

.form-field-error {
  font: 400 15px/24px "Source Sans Pro", sans-serif;
  color: #e3170a;
}
.form-field-warning {
  font: 400 15px/24px "Source Sans Pro", sans-serif;
  color: #c27b13;
}
.focus-view .loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.password-input-container {
  position: relative;
}
.password-input {
  padding: 0.5em 10px 0.5em 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: 0;
  width: 100%;
  color: #111;
}
.password-input--has-errors {
  color: #e3170a;
  border-color: #e3170a;
}
.password-input[disabled] {
  color: #111;
  background: #f3f3f5;
}
.password-input:focus {
  color: #111;
  border-color: #111;
}
.password-input__icon {
  position: absolute;
  top: 8px;
  right: 8px;
  color: #333;
}

.login-form {
  margin-top: 16px;
  width: 100%;
}

.login-form .btn--primary {
  margin-top: 24px;
}

.login-form .btn--white {
  margin-top: 16px;
}

.login-form__buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.forgot-password-form {
  margin-top: 16px;
  width: 100%;
}

.forgot-password-form .btn--primary {
  margin-top: 24px;
}

.forgot-password-form .btn--white {
  margin-top: 16px;
}

.forgot-password-form__buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.app-icon {
  border-radius: 3px;
  margin-top: 0.6rem;
  display: none;
  cursor: pointer;
  border-style: solid;
  border-width: 3px;
  border-radius: 12px;
  border-color: transparent;
  -webkit-transition : border 300ms ease;
  transition : border 300ms ease;
}

.app-icon:hover {
  border-style: solid;
  border-width: 3px;
  border-radius: 12px;
  border-color: #ccc;
}

.app-icon--selected {
  border-style: solid;
  border-width: 3px;
  border-radius: 12px;
  border-color: #999;
}

.app-icon img {
  width: 50px;
}

.app-icon__tooltip {
  color: #fff;
  position: absolute;
  left: 80px;
  height: 50px;
  padding: 0.5rem;
  background: #111;
  z-index: 5;
  border-radius: 9px;
  opacity: 0;
  pointer-events: none;
  -webkit-transition: left 0.3s ease, opacity 0.3s ease;
  transition: left 0.3s ease, opacity 0.3s ease;
}

.app-icon:hover .app-icon__tooltip {
  opacity: 1;
  left: 72px;
}

/* @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none)
{
  select option:disabled::-ms-value{
    color:yellow !important;
  }
} */
/* select[disabled='disabled']::-ms-value {
  // stuff for select works, but not for disabled option (works for enabled option though)
  color: #FFF;
} */
.select-box {
  position: relative;
}
select.select-box__select::-ms-expand {
  display: none;
}
.select-box__select {
  padding: 0.5em 26px 0.5em 10px;
  border-radius: 3px;
  border: 1px solid #ccc;
  outline: 0;
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.select-box--dark .select-box__select {
  background: #474d64;
  color: #fff;
}
.select-box__select[disabled] {
  color: #111;
  background: #f3f3f5;
}
.select-box--light .select-box__select:focus {
  border-color: #111;
}
.select-box__icon {
  position: absolute;
  right: 8px;
  top: 12px;
  font-size: 16px;
  pointer-events: none;
}
.select-box--dark .select-box__icon {
  color: #fff;
}
.select-box__select--has-errors {
  border-color: #e3170a;
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .select-box__icon {
    top: 12px;
  }
}

.month-picker {
  position: relative;
  margin-right: auto;
  display: flex;
  align-items: center;
}

.month-picker__hidden-select {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.month-picker__year {
  margin-right: auto;
}

.month-picker__month {
  font-weight: bold;
  margin-right: 0.25em;
}

.month-picker .material-icons {
  font-size: 14px;
  margin-left: 3px;
}

.datepicker__header {
  display: flex;
  align-items: center;
  padding: 1rem;
  padding-bottom: 0;
  cursor: pointer;
  max-width: 246px;
  margin: 0 auto;
}

.datepicker__header .material-icons {
  font-size: 18px;
}

.datepicker__heading {
  width: 16px;
  text-align: center;
  font-weight: bold;
  margin: 8px;
  margin-bottom: 0;
}

.text--small {
  font: 400 15px/24px "Source Sans Pro", sans-serif;
}

.datepicker__day {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 2px;
  padding: 5px;
  border: 1px solid transparent;
  box-sizing: content-box;
}
.datepicker__day:hover:not(.datepicker__day--selected) {
  background-color: #ccc;
  border-color: #ccc;
}
.datepicker__day--today {
  border-color: #111;
}
.datepicker__day--selected {
  background-color: #4aa366;
  color: #fff;
  cursor: default;
  border-color: #4aa366;
}
.datepicker__day--line-through {
  position: relative;
}
.datepicker__day--line-through:before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 1px solid;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.datepicker__day--light {
  color: #ccc;
}
.datepicker__day--light:hover {
  color: #fff;
}
.datepicker__day--light.datepicker__day--line-through:before {
  border-color: #ccc;
}
.datepicker__day--light.datepicker__day--today {
  border-color: transparent;
}
.datepicker__day .text {
  min-width: 1rem;
  text-align: center;
}

.day-table {
  margin: 0 auto;
  margin-top: 8px;
  max-width: 246px;
}

.day-table__row {
  display: flex;
  margin: 0 8px;
}

.day-table__row:last-child {
  margin-bottom: 8px;
}

.day-table__heading {
  width: 16px;
  text-align: center;
  font-weight: bold;
  margin: 8px;
}

.datepicker {
  border: 1px solid #ccc;
  border-width: 1px 0;
  font: 400 15px/24px "Source Sans Pro", sans-serif;
  margin: 1em;
}
.datepicker--dark {
  color: #fff;
  background: #40465b;
}
.datepicker--dark .datepicker__day--today {
  border-color: #ccc;
}

.custom-control {
  position: relative;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  width: 288px;
  overflow: hidden;
  height: 42px;
}
.custom-control-open {
  position: relative;
  border: 1px solid #333;
  padding: 8px;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  height: 42px;
}
.custom-control-list {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #333;
  border-radius: 0 0 4px 4px;
  padding: 0;
  margin: 0;
  top: 40px;
  left: -1px;
  width: 288px;
}
.custom-control-header {
  width: 270px;
  overflow: hidden;
  white-space: nowrap;
}
.custom-control--dark .custom-control-header {
  color: #fff;
}
.custom-control--dark.custom-control-open {
  border-color: #ccc;
}
.custom-control--dark .custom-control-list {
  border-color: #ccc;
  background: #40465b;
}
.custom-control-header:focus {
  outline: none;
}
.custom-control-header .material-icons {
  position: absolute;
  top: 12px;
  right: 8px;
  font-size: medium;
}

.check-box {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  word-break: normal;
  margin-left: 16px;
  line-height: 24px;
  vertical-align: middle;
}
input[type="checkbox"] {
  display: none;
}
.check-box__icon {
  padding-right: 14px;
  display: inline-block;
  color: #ccc;
  font-size: 16px;
  vertical-align: middle;
  border: 1px solid #ccc;
  height: 16px;
  width: 16px;
  margin-bottom: 2px;
  margin-right: 8px;
  color: #4aa366;
}
.check-box__icon--checked {
  border-color: #4aa366;
}
.not-a-real-selector {
  font-family: "Material Icons";
  padding: 0;
  content: "check";
  margin-right: 8px;
  color: #4aa366;
  font-size: 14px;
  vertical-align: top;
  border: 1px solid #4aa366;
  display: inline;
  max-height: 16px;
}

.app-wrapper {
  display: flex;
  flex-direction: column;
  width: calc(100% - 80px);
}
.nav-wrapper {
  display: flex;
  overflow: auto;
  height: 100%;
}
.inner-nav {
  display: flex;
  padding-left: 8px;
  padding-right: 8px;
  background: #474d64;
}
.inner-nav a,
.secondary-nav a,
.inner-nav .check-box {
  color: white;
}
.inner-nav .check-box__label {
  font: 400 15px/24px "Source Sans Pro", sans-serif;
}
.inner-nav__left {
  margin-right: auto;
}
.inner-nav-list {
  display: flex;
  align-items: center;
  height: 80px;
  margin-top: 0;
  margin-bottom: 0;
}
.inner-nav-list__item {
  display: flex;
  flex-direction: column;
  margin-right: 64px;
  cursor: pointer;
  white-space: nowrap;
}
.inner-nav-list__item:last-child {
  margin-right: 0;
}
.inner-nav-list--tight .inner-nav-list__item {
  margin-right: 12px;
}
.inner-nav-list__item .form-control {
  margin-top: 10px;
}
.inner-nav-list__item--selected {
  font-weight: bold;
}
.inner-nav-list__item--selected .selected-lozenge {
  opacity: 1;
}
.inner-nav-list__item:hover .selected-lozenge {
  opacity: 1;
}
.secondary-nav__list {
  display: flex;
  margin: 0;
  padding: 0;
  padding-left: 48px;
  height: 60px;
  background: #0a132f;
  align-items: center;
  color: white;
}
.menu-selected-date {
  color: white;
  cursor: pointer;
}
.menu-date-picker {
  position: absolute;
  color: white;
  top: 80px;
  right: 0px;
  z-index: 20;
  border: 1px solid #474d64;
  border-radius: 4px;
  padding: 8px;
  color: black;
  background: #fff;
}
.search-input {
  padding: 8px;
  border-radius: 4px;
  height: 42px;
  border: 1px solid #ccc;
  font: 400 15px/24px "Source Sans Pro", sans-serif;
  color: #fff;
  background: none;
  outline: none;
  min-width: 300px;
}
.selected-lozenge {
  background: -webkit-gradient(linear, left top, left bottom, from(#13b8e6), to(#2f6cb4));
  background: -webkit-linear-gradient(top, #13b8e6 0%, #2f6cb4 100%);
  background: linear-gradient(180deg, #13b8e6 0%, #2f6cb4 100%);
  height: 5px;
  position: relative;
  top: 21px;
  border-radius: 2px 2px 0 0;
  opacity: 0;
  -webkit-transition: opacity 1s;
  transition: opacity 1s;
}
.secondary-nav .selected-lozenge {
  top: 3px;
  background: white;
  height: 2px;
}
.secondary-nav .inner-nav-list__item--selected {
  font-weight: normal;
}

.landing-center {
  padding: 16px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  margin: 1rem 1rem;
  margin-right: 2rem;
  align-items: flex-start;
  width: 100%;
}

.recover-account-form {
  margin-top: 16px;
  width: 100%;
}

.recover-account-form .btn--primary {
  margin-top: 24px;
}

.recover-account-form .btn--white {
  margin-top: 16px;
}

.recover-account-form__buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-box {
  padding: 0.5em 10px 0.5em 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: 0;
  width: 100%;
  color: #111;
}
.text-box:focus {
  border-color: #111;
}

.time-picker {
  position: relative;
}
.time-picker__input {
  padding: 0.5em 10px 0.5em 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: 0;
  width: 100%;
}
.time-picker__icon {
  position: absolute;
  right: 8px;
  top: 8px;
  pointer-events: none;
  color: #4aa366;
}

.date-input {
  position: relative;
}
.date-input__input {
  padding: 0.5em 10px 0.5em 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  outline: 0;
  width: 100%;
}
.date-input__input[disabled] {
  color: #111;
  background: #f3f3f5;
}
.date-input__input:focus {
  border-color: #111;
}
.date-input__icon {
  position: absolute;
  right: 8px;
  top: 8px;
  pointer-events: none;
  color: #4aa366;
}

.notice-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #111;
  opacity: 0.3;
  z-index: 10;
}
.notice {
  padding: 16px;
  border: 2px solid;
  border-radius: 3px;
  width: 40%;
  background-color: white;
  font: 400 15px/24px "Source Sans Pro", sans-serif;
  position: fixed;
  top: 40%;
  left: 30%;
  z-index: 100;
}
.notice--success {
  color: #71b340;
  background: #f1f7ec;
}
.notice--error {
  color: #e3170a;
  background: #ffe8e7;
}
.notice__icon {
  font-size: 18px;
}
.notice__icon--dismiss {
  cursor: pointer;
}
.notice__message {
  margin: 0.5rem 0.5rem 16px 0.5rem;
}

.button-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-col .btn {
  margin-bottom: 1rem;
}

.right-panel {
  display: none;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  padding: 1em;
  border-left: 1px solid #ccc;
  overflow-y: scroll;
  z-index: 100;
}
.right-panel--dark {
  background: #333;
  border-left: 1px solid #111;
  color: #fff;
}
.right-panel--expanded {
  display: inherit;
}
.right-panel__header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.right-panel__title {
  font: 400 21px/32px "Source Sans Pro", sans-serif;
  font-weight: bold;
}
.right-panel__close {
  margin-left: auto;
  cursor: pointer;
}
@media (max-width: 1400px) {
  .right-panel {
    right: -80px;
  }
}

.round-btn-fixed {
  position: fixed;
  border: none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font: 400 21px/32px "Source Sans Pro", sans-serif;
  font-weight: bold;
  height: 48px;
  width: 48px;
  align-items: center;
  display: flex;
  justify-content: center;
  right: 16px;
  bottom: 16px;
  cursor: pointer;
  background-color: #4aa366;
}
.round-btn-absolute {
  position: absolute;
  border: none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font: 400 21px/32px "Source Sans Pro", sans-serif;
  font-weight: bold;
  height: 48px;
  width: 48px;
  align-items: center;
  display: flex;
  justify-content: center;
  right: -24px;
  top: -24px;
  cursor: pointer;
  background-color: #4aa366;
}
.round-btn-hover {
  position: fixed;
  border: none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font: 400 21px/32px "Source Sans Pro", sans-serif;
  font-weight: bold;
  height: 56px;
  width: 56px;
  align-items: center;
  display: flex;
  justify-content: center;
  right: 36px;
  bottom: 32px;
  cursor: pointer;
  background-color: #4aa366;
}
.round-btn-hover:hover {
  border: 1px solid black;
}
.hoverMenu {
  position: fixed;
  right: 48px;
  bottom: 100px;
}

.calendar-view {
  background-color: #f3f3f5;
  width: 100%;
  display: flex;
  overflow-x: auto;
}
.calendar-view__loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.booking-calendar {
  position: relative;
}
.booking-calendar .time-slots {
  position: absolute;
  left: 0px;
  top: 0px;
}
.time-slots {
  flex-direction: column;
  position: fixed;
  left: 350px;
  top: 95px;
  z-index: 1;
  background: #f3f3f5;
  height: 1920px;
  /* This is the equivalent of height: max-content */
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .time-slots {
    left: 340px;
  }
}
.booking-slots {
  position: relative;
  flex-shrink: 0;
}
.slot {
  height: 40px;
}
.slot:nth-child(odd) {
  border-bottom: 1px dashed rgba(204, 204, 204, 0.3);
  border-top: 1px solid rgba(204, 204, 204, 0.3);
}
.slot:first-child {
  border-top: none;
}
.slot:last-child {
  border-bottom: 1px solid #ccc;
}
.booked {
  width: 100%;
  cursor: pointer;
  padding: 2px 0px;
}
.bookings {
  width: 100%;
  position: absolute;
  top: 0;
  left: 85px;
  height: 1920px;
  display: flex;
}
.col {
  display: block;
  width: 288px;
  position: relative;
  flex-shrink: 0;
}
.timeslot-info {
  position: relative;
  border-radius: 3px;
  box-sizing: border-box;
  height: 100%;
  margin: 0 38px 2px 12px;
  font: 400 15px/24px "Source Sans Pro", sans-serif;
  overflow: hidden;
  width: 100%;
}
.booking-info {
  position: relative;
  padding: 8px;
  border-radius: 3px;
  box-sizing: border-box;
  height: 100%;
  margin: 0px 2px;
  font: 400 15px/24px "Source Sans Pro", sans-serif;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #ccc;
}
.internal-booking {
  border-color: #006493;
}
.booking-info:hover:not(.booking-info--selected) {
  background: #ccc;
}
.booking-info--overdue-gravity-1:not(.booking-info--selected) {
  background: #ded121;
  color: #fff;
  border-color: #ded121;
}
.booking-info--overdue-gravity-1:hover:not(.booking-info--selected) {
  background: #ccc016;
  color: #fff;
  border-color: #ccc016;
}
.booking-info--overdue-gravity-2:not(.booking-info--selected) {
  background: #de9121;
  color: #fff;
  border-color: #de9121;
}
.booking-info--overdue-gravity-2:hover:not(.booking-info--selected) {
  background: #c27b13;
  color: #fff;
  border-color: #c27b13;
}
.booking-info--overdue-gravity-3:not(.booking-info--selected) {
  background: #de4121;
  color: #fff;
  border-color: #de4121;
}
.booking-info--overdue-gravity-3:hover:not(.booking-info--selected) {
  background: #b53112;
  color: #fff;
  border-color: #b53112;
}
.booking-info--selected {
  background: #4aa366;
  color: #fff;
  border-color: #4aa366;
}
.text-header {
  font-weight: bold;
}

.list-view-body {
  width: 100%;
}

.quick-search-list {
  margin: 0;
  padding: 0;
  position: relative;
  border: 1px solid #ccc;
  top: -11px;
  border-radius: 3px;
}

.quick-search-item {
  list-style: none;
  padding: 0 0.5rem;
}

.quick-search-item:hover {
  background: #ccc;
}

.default-slot-form {
  position: relative;
}
.default-slot-form .button-col,
.default-slot-form .button-col .btn {
  display: block;
}
.slot-config-table td {
  padding: 0px 8px;
  vertical-align: inherit;
}
.default-slot-form-title {
  margin-left: 16px;
}
.slot-config-reset,
.slot-config-copy {
  cursor: pointer;
}
.slot-copy-to-disabled {
  color: #ccc;
}
.slot-copy-to {
  cursor: pointer;
}
.slot-copy {
  width: 112px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  font: 400 15px/24px "Source Sans Pro", sans-serif;
  color: #111;
  z-index: 100;
  position: absolute;
  padding: 8px;
  top: 27px;
  right: 0;
  left: -32px;
}
.slot-copy:before {
  content: "";
  position: absolute;
  top: -16px;
  left: 30px;
  z-index: 1;
  border: solid 8px transparent;
  border-bottom-color: #ccc;
}
.slot-copy:after {
  content: "";
  position: absolute;
  top: -15px;
  left: 30px;
  z-index: 1;
  border: solid 8px transparent;
  border-bottom-color: #fff;
}
.default-slot-form-hint {
  font: 400 15px/24px "Source Sans Pro", sans-serif;
  color: #333;
  margin: 16px 16px -8px 16px;
}
.slot-config-table .copy-slot {
  font-size: small;
  vertical-align: middle;
}
.copy-menu {
  position: relative;
}

.admin-item {
  height: 74px;
  position: relative;
  width: 298px;
  border-top: 1px solid #999;
  padding: 8px 16px;
  cursor: pointer;
  border-left: 8px solid #0a132f;
}
.admin-item--selected {
  border-left: 8px solid #4aa366;
}
.admin-item:last-child {
  border-bottom: 1px solid #999;
}
.admin-item-title {
  font: 400 21px/32px "Source Sans Pro", sans-serif;
}
.admin-item-text-row {
  display: flex;
  justify-content: space-between;
}
.admin-item-text {
  font: 400 15px/24px "Source Sans Pro", sans-serif;
}
.admin-item-right-text {
  font: 400 15px/24px "Source Sans Pro", sans-serif;
  align-self:flex-end;
}
.admin-item-icon {
  position: absolute;
  right: 16px;
  top: 20px;
}

.admin-panel__items {
  overflow-y: scroll;
  margin-bottom: 64px;
}
.admin-details {
  width: 100%;
  overflow-y: scroll;
}
.admin-details-title {
  margin-left: 62px;
  font: 700 48px/56px "Source Sans Pro", sans-serif;
}
.admin-details-location {
  margin-left: 62px;
  font: 700 36px/40px "Source Sans Pro", sans-serif;
  margin-bottom: 24px;
}
.admin-details-main {
  background-color: #fff;
  border-radius: 24px;
  margin-left: 32px;
  padding: 32px;
  width: 992px;
}
.admin-menu-item {
  display: inline-block;
  margin-right: 32px;
}
.admin-menu-item-title {
  font: 400 15px/24px "Source Sans Pro", sans-serif;
}
.admin-menu-item-selected {
  display: inline-block;
  margin-right: 32px;
  border-bottom: 2px solid #4aa366;
}

.sidebar {
  background: #0a132f;
  color: #fff;
  display: flex;
  flex-direction: column;
}
.sidebar--offset {
  margin-left: 80px;
}
.sidebar--min-height {
  min-height: 40px;
}
.sidebar-filter {
  margin: 0px 14px;
}
.sidebar-filters {
  display: none;
}
.sidebar-filters--open {
  display: block;
}
.sidebar-filters__opener {
  background: #0a132f;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  content: "";
  position: absolute;
  left: calc(50% - 20px);
  top: 20px;
  z-index: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.app--offset .sidebar-filters__opener {
  left: calc(50% + 20px);
}
.sidebar-filters--open + .sidebar-filters__opener {
  top: 350px;
}
.navigation-bar {
  margin: 0px 14px;
  height: 40px;
  display: flex;
  align-items: center;
}
.navigation-bar--open {
  position: fixed;
  flex-direction: column;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100vh;
  width: 80px;
  background: #40465b;
  margin: 0;
}
.navigation-bar--open .app-icon {
  display: block;
}
.navigation-bar--open .app-suite-logo {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  margin: 0;
  margin-top: 1rem;
}
.navigation-bar--open .navigation-bar__menu {
  position: absolute;
  top: 8px;
  left: 94px;
}
.navigation-bar--open + .sidebar-filters {
  margin-top: 40px;
}
.navigation-bar__menu {
  cursor: pointer;
}
.app-suite-logo {
  height: 36px;
  width: 36px;
  margin-left: auto;
  text-align: center;
}
.app-suite-logo img {
  max-width: 28px;
  max-height: 28px;
}
.navigation-bar--open .app-suite-logo img {
  max-width: 50px;
  max-height: 50px;
}
.sidebar {
  flex-direction: row;
}
.navigation-bar {
  width: 80px;
  min-width: 80px;
  background: #40465b;
  margin: 0;
  height: 100vh;
  flex-direction: column;
}
.navigation-bar__menu {
  display: none;
}
.app-suite-logo {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.app-suite-logo img {
  max-width: 50px;
  max-height: 50px;
}
.app-icon {
  display: block;
}
.sidebar-filters {
  margin-top: 1rem;
  min-width: 260px;
}

.user-roles-table {
  margin-left: 16px;
}
.user-roles-table .form-control {
  padding: 8px 0 0 0;
}
.user-roles-table td {
  border: 1px solid #f3f3f5;
}
.user-roles-table .material-icons {
  vertical-align: middle;
  font-size: 16px;
  cursor: pointer;
}
.tag {
  border: 1px solid #f3f3f5;
  background-color: #f3f3f5;
  margin: 2px 4px;
  border-radius: 4px;
  padding: 2px 4px;
  cursor: pointer;
}
.user-roles {
  margin: 8px;
  border-radius: 4px;
  max-width: 300px;
  position: relative;
  display: flex;
  flex-direction: column;
}
.add-role {
  display: inline-block;
}
.save-role {
  position: absolute;
  top: 50%;
  right: -16px;
}
.current-tag {
  background-color: #f1f7ec;
}
.new-tag {
  background-color: #ffe8e7;
}
.multi-select-list > li {
  list-style: none;
}

.admin-menu-item {
  display: inline-block;
  margin-right: 32px;
  cursor: pointer;
}
.admin-menu-item-title {
  font: 400 15px/24px "Source Sans Pro", sans-serif;
}
.admin-menu-item-selected {
  display: inline-block;
  margin-right: 32px;
  border-bottom: 2px solid #4aa366;
  cursor: pointer;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
  vertical-align: top;
  margin-right: 8px;
}
/* The switch can be shown in a disabled state */
.switch-label[data-disabled="true"],
.switch-label[data-disabled="true"] .slider {
  opacity: 0.6;
  cursor: not-allowed;
}
/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #111;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 30px;
}
.slider:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 4px;
  bottom: 6px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
input:checked + .slider {
  background-color: #4aa366;
}
input:focus + .slider {
  box-shadow: 0 0 1px #4aa366;
}
input:checked + .slider:before {
  -webkit-transform: translateX(20px);
          transform: translateX(20px);
}


.admin-main {
  padding: 16px;
  font: 400 15px/24px "Source Sans Pro", sans-serif;
}
.sub-site {
  padding-left: 16px;
}
.icon-button {
  font-size: 16px;
  color: #333;
  cursor: pointer;
}
.zones-dock-table .form-control {
  padding: 0px;
  margin-bottom: 0px;
}
.zones-dock-table {
  text-align: center;  
}
.dock-safety-device-table {
display: flex;
width:100%;
}

.admin-form {
  width: 30%;
  display: flex;
}
.admin-form .form-control {
  vertical-align: top;
  padding: 20px;
}
.admin-form .button-row {
  margin: 0.75rem 0.75rem 0.75rem 0;
}
.slot-config-table {
  margin-left: 16px;
}
.slot-config-table .form-control {
  padding: 8px 0 0 0;
}
.slot-config-table td {
  border-bottom: 2px solid #f3f3f5;
}
.slot-config-table .material-icons {
  vertical-align: middle;
}
.copy-menu {
  cursor: pointer;
}
.copy-clear {
  display: flex;
  flex-flow: wrap;
  padding-bottom: 8px;
}
.copy-functions,
.clear-functions {
  flex: 0 0 50%;
}
.site-details-form .button-col,
.site-details-form .button-col .btn {
  display: block;
}

.slot-config-table .select-box__select {
  width: 100%;
  padding: 0 0 0 8px;
  color: #333;
}
.slot-config-table .select-box__icon {
  top: 8px;
  color: #111;
}
.slot-config-table .form-control {
  display: inline-block;
  margin-left: 8px;
  width: 72px;
}
.date-picker-modal {
  position: absolute;
  z-index: 1000;
  border: 2px solid #333;
  background-color: #fff;
  border-radius: 4px;
  left: 16px;
  width: 278px;
}
.time-slot {
  width: 155px;
}
.slot-override {
  background-color: #999;
  color: #fff;
}
.slot-override:after {
  font-family: "Material Icons";
  content: "close";
  color: #999;
  background-color: #fff;
  border-radius: 50%;
  font-size: 16px;
  margin-left: 8px;
  padding-left: 1px;
  cursor: pointer;
}
.slot-date-select {
  width: 30%;
  display: inline-block;
  margin-left: 16px;
  margin-right: 16px;
}
.slot-clear-button {
  margin-left: 164px;
  display: inline-block;
}
.slot-config-form {
  position: relative;
}
.slot-config-form .button-col,
.slot-config-form .button-col .btn {
  display: block;
}
.slot-config-form-title {
  margin: 16px;
}
.slot-config-form-hint {
  font: 400 15px/24px "Source Sans Pro", sans-serif;
  color: #ccc;
  margin: -8px 16px 16px 16px;
}

.modal {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: 110;
}
.modal__content {
  max-height: 98%;
  background: #fff;
  border-radius: 0.5rem;
  overflow-y: auto;

}
.modal__body {
  padding: 2rem;
}
.modal__buttons {
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
}
.modal__buttons > .btn:last-child {
  margin-left: 0.5rem;
}
.modal__header {
  display: flex;
  align-items: center;
  background: #40465b;
  color: #fff;
  padding: 1rem;
  border-radius: 0.5rem 0.5rem 0 0;
}
.modal__title {
  font: 400 21px/32px "Source Sans Pro", sans-serif;
  font-weight: bold;
}
.modal__close {
  margin-left: auto;
  cursor: pointer;
}

.modal {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: 110;
}
.modal__content {
  background: #fff;
  border-radius: 0.5rem;
}
.modal__body {
  padding: 2rem;
}
.modal__buttons {
  margin-top: 1rem;
  display: flex;
  justify-content: space-around;
}
.modal__buttons > .btn:last-child {
  margin-left: 0.5rem;
}
.modal__header {
  display: flex;
  align-items: center;
  background: #40465b;
  color: #fff;
  padding: 1rem;
  border-radius: 0.5rem 0.5rem 0 0;
}
.modal__title {
  font: 400 21px/32px "Source Sans Pro", sans-serif;
  font-weight: bold;
}
.modal__close {
  margin-left: auto;
  cursor: pointer;
}

.barcode__canvas {
  margin-bottom: 1rem;
}

.application-table__header {
  text-align: center;
  width: 50%;
}

.application-table__td {
  padding: 24px 4px;
}

.application-table__message {
  color: red;
}

.remove {
  color: #e3170a;
}
.resend,
.remove {
  cursor: pointer;
}
.invites-title {
  font: 400 21px/32px "Source Sans Pro", sans-serif;
}

.multi-select {
  position: relative;
  border: 1px solid #ccc;
  padding: 8px;
  border-radius: 4px;
  cursor: pointer;
  width: 288px;
  overflow: hidden;
  height: 42px;
}
.multi-select-open {
  position: relative;
  border: 1px solid #333;
  padding: 8px;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
  height: 42px;
}
.multi-select-list {
  position: absolute;
  z-index: 1000;
  background-color: #fff;
  border: 1px solid #333;
  border-radius: 0 0 4px 4px;
  padding: 0;
  margin: 0;
  top: 40px;
  left: -1px;
  width: 288px;
}
.multi-select-list li {
  padding: 8px;
  margin: 0;
  border-radius: 0;
  border: 1px solid #fff;
  background-color: #fff;
}
.multi-select-list li:hover {
  background-color: #f3f3f5;
}
.multi-select-header {
  width: 270px;
  overflow: hidden;
  white-space: nowrap;
}
.multi-select-header:focus {
  outline: none;
}
.multi-select-header:after {
  font-family: "Material Icons";
  content: "arrow_drop_down";
  vertical-align: bottom;
  position: absolute;
  top: 8px;
  right: 0;
  font-size: 24px;
  background-color: #fff;
  background: -webkit-gradient(
    linear,
    left top, right top,
    from(transparent),
    color-stop(12%, #fff),
    to(#fff)
  );
  background: -webkit-linear-gradient(
    left,
    transparent,
    #fff 12%,
    #fff
  );
  background: linear-gradient(
    90deg,
    transparent,
    #fff 12%,
    #fff
  );
  /* IE11 Support */
  -webkit-font-feature-settings: "liga";
          font-feature-settings: "liga";
}
.multi-select-list li:last-child {
  border-radius: 0 0 4px 4px;
}
.selected-item {
  font-weight: bold;
}
.selected-item:after {
  font-family: "Material Icons";
  content: "checked";
  vertical-align: bottom;
  margin-left: 8px;
  color: #71b340;
  font-weight: bold;
  font-size: 20px;
  /* IE11 Support */
  -webkit-font-feature-settings: "liga";
          font-feature-settings: "liga";
}
.multi-select__icon {
  position: absolute;
  right: 8px;
  top: 16px;
  font-size: 16px;
  pointer-events: none;
  color: #111;
}

.user-details-form {
  margin-top: 16px;
}
.user-details-title {
  font: 400 21px/32px "Source Sans Pro", sans-serif;
}
.danger-zone {
  border: 1px solid #ddd;
  padding: 12px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 1.4em;
}
.danger-zone .caption {
  font-size: 14px;
  font-weight: 900;
  margin: 0 0 6px 0;
}
.danger-zone .cta {
  margin-top: 12px;
}
.flex-start {
  align-items: flex-start;
}
.settings-title {
  margin-left: 1rem;
}

.setting-tile-list {
  display: flex;
  flex-wrap: wrap;
  width: 93vw;
}

.setting-tile {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
  background: #fff;
  width: 12rem;
  height: 12rem;
}

.setting-tile a {
  text-align: center;
  text-decoration: none;
  color: #111;
}

.setting-tile i {
  display: block;
  font-size: 5rem;
}

.user-roles-table {
  margin-left: 16px;
}
.user-roles-table .form-control {
  padding: 8px 0 0 0;
}
.user-roles-table td {
  border: 1px solid #f3f3f5;
}
.user-roles-table .material-icons {
  vertical-align: middle;
  font-size: 16px;
  cursor: pointer;
}
.tag {
  border: 1px solid #f3f3f5;
  background-color: #f3f3f5;
  margin: 2px 4px;
  border-radius: 4px;
  padding: 2px 4px;
  cursor: pointer;
}
.user-roles {
  margin: 8px;
  border-radius: 4px;
  max-width: 300px;
  position: relative;
  display: flex;
  flex-direction: column;
}
.add-role {
  display: inline-block;
}
.save-role {
  position: absolute;
  top: 50%;
  right: -16px;
}
.current-tag {
  background-color: #f1f7ec;
}
.new-tag {
  background-color: #ffe8e7;
}
.multi-select-list > li {
  list-style: none;
}
.zones-dock-table .form-control {
  padding: 0px;
  margin-bottom: 0px;
}
.zones-dock-table {
  text-align: center;  
}
.dock-safety-device-table {
display: flex;
width:100%;
}
.admin-main {
  padding: 16px;
  font: 400 15px/24px "Source Sans Pro", sans-serif;
}
.sub-site {
  padding-left: 16px;
}
.icon-button {
  font-size: 16px;
  color: #333;
  cursor: pointer;
}
.admin-form {
  width: 50%;
  display: flex;
}
.admin-form .form-control {
  vertical-align: top;
  padding: 20px;
}
.admin-form .button-row {
  margin: 0.75rem 0.75rem 0.75rem 0;
}
.slot-config-table {
  margin-left: 16px;
}
.slot-config-table .form-control {
  padding: 8px 0 0 0;
}
.slot-config-table td {
  border-bottom: 2px solid #f3f3f5;
}
.slot-config-table .material-icons {
  vertical-align: middle;
}
.copy-menu {
  cursor: pointer;
}
.copy-clear {
  display: flex;
  flex-flow: wrap;
  padding-bottom: 8px;
}
.copy-functions,
.clear-functions {
  flex: 0 0 50%;
}
.site-details-form .button-col,
.site-details-form .button-col .btn {
  display: block;
}
.stand-level-table {
  width: 100%;
}
.stand-level-table .form-control {
  padding: 0;
}
.stand-level-table td {
  white-space: nowrap;
}
.stand-level-table .site-label {
  padding-top: 16px;
}
.booking-receipt-table-header-end {
  text-align: end;
}
.booking-receipt-table-row-end {
  text-align: end;
  padding-right: 16px;
  padding-top: 10px;
}
.booking-receipt-add-receipt {
  text-align: end;
  padding-top: 8px;
  padding-right: 8px;
}
.booking-receipt-table-row-switch {
  padding-left: 16px;
  padding-top: 14px;
}
.booking-receipt-table-input-row {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
.booking-receipt-table-input {
  padding-left: 8px;
  width: 45%;
}

.appt-info td {
  padding: 0 5px 0 0;
}

.appt-table tr {
  border-bottom: 1px solid #eee;
}

.appt-info tr {
  border-bottom: 0;
}

.note-box {
  background: rgba(204, 204, 204, 0.3);
  border-radius: 5px;
  margin-top: auto;
}
.note-box__header {
  background: #40465b;
  color: #fff;
  border-radius: 5px 5px 0 0;
  display: flex;
  align-items: center;
  font-weight: bold;
  padding: 0 0.5rem;
}
.note-box__body {
  padding: 0.5rem;
  max-width: 330px;
}
.data-row {
  display: flex;
  justify-content: space-between;
}
.data-row__heading {
  font-weight: bold;
  margin-right: 0.25rem;
}

.dash-row {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}
.dash-row > * {
  margin-right: 1rem;
  flex: 1 1;
}
.dash-row > *:last-child {
  margin-right: 0;
}
.barGraph {
  position: relative;
}
.bars,
.tools,
.labels {
  position: absolute;
  left: 0px;
  top: 0px;
}
.bars {
  z-index: 0;
}
.tools {
  z-index: 1;
}
.labels {
  z-index: 2;
}
.notify-text {
  font-size: smaller;
  white-space: nowrap;
}
.traffic-dash-row {
  text-align: center;
}
.dash-row .traffic-dash-row {
  margin-bottom: 0;
}
.traffic-dash-row .content-block {
  padding: 0 0 0.5em 0;
  margin-top: 8px;
  margin-bottom: 8px;
}
.traffic-dash-row h1 {
  margin: 0.5em 0 0 0;
}
.dash-row .status {
  text-transform: capitalize;
}
.traffic-dash-row h3 {
  margin-top: 0px;
}
.horizontal-tile {
  display: flex;
  position: relative;
  padding: 0.5rem 1rem;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background: #0a132f;
  color: #fff;
  margin-bottom: 0.5rem;
  border-radius: 1rem;
}
.horizontal-tile__expand {
  position: relative;
  top: 4px;
  cursor: pointer;
}
.horizontal-tile__count {
  margin-left: auto;
  margin-right: 2.5rem;
}
.horizontal-tile__count--small-margin {
  margin-right: 1rem;
}
.horizontal-tile__desc {
  display: flex;
  flex-direction: column;
}
.horizontal-tile__desc > span:first-child {
  font: 400 15px/24px "Source Sans Pro", sans-serif;
}
table.trailer-breakdown {
  font: 400 21px/32px "Source Sans Pro", sans-serif;
}
.trailer-breakdown td:last-child {
  text-align: right;
  padding-right: 3rem;
}

.dnd-table {
  background: transparent;
  border-collapse: separate !important;
  border-spacing: 0;
}

.dnd-table tbody {
  background: #ccc;
}

.dnd-header .dnd-handle {
  border-left: 3px solid #0a132f;
}

.dnd-row .dnd-handle {
  border-left: 3px solid #fff;
}

.dnd-row {
  background: #fff;
}

.dnd-row--dragging {
  box-shadow: 0 1px 3px #111;
  background: #fff;
}

.dnd-row--dragging .dnd-handle {
  border-color: #fff;
}

.dnd-row--changed .dnd-handle {
  border-color: #de9121;
}

.list-view {
  margin-left: 16px;
}
.list-view h3,
.list-view h5 {
  margin-left: 8px;
}
.dnd-table {
  background: #ccc;
  border-collapse: separate !important;
  border-spacing: 0;
}
.dnd-header .dnd-handle {
  border-left: 3px solid #0a132f;
}
.dnd-row .dnd-handle {
  border-left: 3px solid #fff;
}
.dnd-row {
  background: #fff;
}
.dnd-row--dragging {
  box-shadow: 0 1px 3px #111;
  background: #fff;
}
.dnd-row--dragging .dnd-handle {
  border-color: #fff;
}
.dnd-row--changed .dnd-handle {
  border-color: #de9121;
}

.trailer-filter-form .multi-select {
  width: auto;
}

.trailer-filter-form .multi-select-list {
  width: calc(100% + 2px);
}

.table-filter-bar {
  display: flex;
  margin: 0 8px;
}
.capsule {
  font: 400 15px/24px "Source Sans Pro", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f6faf7;
  border-radius: 5px;
  padding: 0 0.5rem;
  border: 1px solid #4aa366;
}
.capsule__title {
  font-weight: bold;
  margin-right: 0.5rem;
}
.table-filter-bar .capsule {
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.table-filter-bar .btn {
  width: auto;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.table-filter-bar > .btn {
  width: auto;
  margin-left: auto;
  margin-right: 0;
  flex-shrink: 0;
}
.table-filter-bar > .flex {
  flex-wrap: wrap;
}

.full-screen-exit-hint {
  font: 400 15px/24px "Source Sans Pro", sans-serif;
  color: #999;
  margin: 4px 4px 4px 4px;
}
.full-screen-body {
  background: #1a1a1a;
  color: #fff;
  height: 100%;
  padding: 3% 5% 2.5% 5%;
}
.flightboard-container {
  height: 100%;
}
.slick-slider {
  height: 100%;
}
.flightboard-table {
  width: 100%;
  margin: auto;
  background: transparent;
}
.flightboard-table tbody {
  background: transparent;
}
.flightboard-table thead tr th {
  font-family: pill-gothic-300mg, sans-serif;
  font-size: 34px;
  height: 5rem;
  background: #2c2c2c;
  text-align: left;
  vertical-align: middle;
  text-transform: uppercase;
}
.flightboard-table tbody td {
  font-family: pill-gothic-300mg, sans-serif;
  font-size: 35px;
  height: 6rem;
  text-align: left;
  vertical-align: middle;
  text-transform: uppercase;
}
.flightboard-table tbody tr:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}
.slick-dots li button:before {
  font-size: 1rem !important;
  color: #fff !important;
}
.flightboard-top-bar {
  height: 0.5rem;
  background: -webkit-gradient(
    linear,
    left top, right top,
    from(rgba(92, 65, 147, 0.8)),
    color-stop(49.89%, rgba(95, 67, 148, 0.6)),
    color-stop(96.94%, rgba(148, 105, 171, 0.4)),
    to(rgba(184, 146, 195, 0.2))
  );
  background: -webkit-linear-gradient(
    left,
    rgba(92, 65, 147, 0.8) 0%,
    rgba(95, 67, 148, 0.6) 49.89%,
    rgba(148, 105, 171, 0.4) 96.94%,
    rgba(184, 146, 195, 0.2) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(92, 65, 147, 0.8) 0%,
    rgba(95, 67, 148, 0.6) 49.89%,
    rgba(148, 105, 171, 0.4) 96.94%,
    rgba(184, 146, 195, 0.2) 100%
  );
}
.flightboard-icon {
  width: 18%;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}
.slick-slide * {
  outline: none !important;
}
.flightboard-status__arrived {
  color: #f4df52;
}
.flightboard-status__trailer_attached {
  color: #f4df52;
}
.flightboard-status__inbound_in_progress {
  color: #499fdc;
}
.flightboard-status__outbound_in_progress {
  color: #499fdc;
}
.flightboard-status__ready_for_driver {
  color: #00a55f;
}
.flightboard-flag img {
  margin: auto;
}

.zone-table td {
  vertical-align: inherit;
}
.zone-table .manual {
  background-color: rgba(255, 255, 10, 0.25);
}
.zone-table .material-icons {
  vertical-align: inherit;
}
.prev-action,
.next-action {
  cursor: pointer;
}
.prev-action-disabled,
.next-action-disabled {
  color: #ccc;
}

.form-hint {
    font: 400 15px/24px "Source Sans Pro", sans-serif;
    color: #999;
    margin: -10px 0px 5px 4px;
}
.dash-row {
  display: flex;
  width: 100%;
  margin-bottom: 1rem;
  flex-wrap: wrap;
}

.dash-row > * {
  margin-right: 1rem;
  flex: 1 1;
}

.barGraph {
  position: relative;
}

.bars,
.tools,
.labels {
  position: absolute;
  left: 0px;
  top: 0px;
}

.bars {
  z-index: 0;
}

.tools {
  z-index: 1;
}

.labels {
  z-index: 2;
}

.list-view-body {
  width: 100%;
}
.schedule-table {
  min-height: 50px;
}
.in-motion {
  -webkit-user-select: "none";
     -moz-user-select: "none";
      -ms-user-select: "none";
          user-select: "none";
  padding: 4px;
  margin: 8px;
  background: #fff;
}
.droppable {
  background: #bfdbf7;
  padding: 8px;
}
.list-view {
  margin-left: 16px;
}
.list-view h3,
.list-view h5 {
  margin-left: 8px;
}
.priority-booking {
  background-color: rgba(255, 255, 10, 0.25);
}

.external-trailer {
  background-color: #eee;
}

/* Containers */
.app {
  display: flex;
  flex-direction: row;
  height: 100vh;
}
.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  /* I'm not sure why but this fixes content disappearing! */
  overflow: hidden;
}
.app--offset .main {
  margin-left: 80px;
  width: 100%;
}
.main-body {
  display: flex;
  width: 100%;
  padding-bottom: 100px;
  align-items: flex-start;
}
.calendar-view__main-body {
  display: flex;
  height: calc(100vh - 80px);
  width: 100%;
}
.form-control {
  margin-bottom: 10px;
  font: 400 15px/24px "Source Sans Pro", sans-serif;
}
.button-row {
  display: flex;
  flex-direction: row;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}
.button-row button {
  flex-grow: 1;
}
.button-row:first-child {
  /* Remove margin from top-most button rows */
  margin-top: 0;
}
.button-row button:first-child:not(:only-child) {
  /* First button */
  margin-right: 3px;
}
.button-row button:last-child:not(:only-child) {
  /* Last button */
  margin-left: 3px;
}
.button-row button:not(:first-child):not(:last-child) {
  /* Buttons between first and last */
  margin-right: 3px;
  margin-left: 3px;
}
.admin-header {
  background-color: #4aa366;
  color: #fff;
  height: 100px;
  top: 0;
  padding-left: 16px;
  width: 100%;
  position: relative;
}
.admin-main {
  display: inline-block;
  overflow-y: auto;
}
.site-config {
  display: flex;
  height: 100vh;
  flex-direction: column;
}
.time-slot-main {
  display: flex;
}
.list-view-main {
  display: flex;
  overflow: auto;
}
.admin-panel {
  display: flex;
  height: 100vh;
  overflow: auto;
}
.admin-sidebar-title {
  margin-top: 96px;
  padding-left: 16px;
  position: relative;
}
.admin-panel-sidebar {
  background: #0a132f;
  color: #fff;
  border-right: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 314px;
}
.admin-sidebar-footer {
  bottom: 0;
  left: 0;
  position: absolute;
  cursor: pointer;
  padding: 1rem;
  background: #0a132f;
  width: 100%;
}
.form-columns {
  display: flex;
}
.form-right-column {
  margin-left: 104px;
}
.content-block {
  background: white;
  border-radius: 20px;
  padding: 1rem 3rem;
}

