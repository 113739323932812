@import "../colours.css";
@import "../typography.css";

.sidebar {
  background: var(--brand-tertiary);
  color: var(--base-white);
  display: flex;
  flex-direction: column;
}

.sidebar--offset {
  margin-left: 80px;
}

.sidebar--min-height {
  min-height: 40px;
}

.sidebar-filter {
  margin: 0px 14px;
}

.sidebar-filters {
  display: none;
}

.sidebar-filters--open {
  display: block;
}

.sidebar-filters__opener {
  background: var(--brand-tertiary);
  width: 40px;
  height: 40px;
  border-radius: 20px;
  content: "";
  position: absolute;
  left: calc(50% - 20px);
  top: 20px;
  z-index: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app--offset .sidebar-filters__opener {
  left: calc(50% + 20px);
}

.sidebar-filters--open + .sidebar-filters__opener {
  top: 350px;
}

.navigation-bar {
  margin: 0px 14px;
  height: 40px;
  display: flex;
  align-items: center;
}

.navigation-bar--open {
  position: fixed;
  flex-direction: column;
  top: 0;
  left: 0;
  z-index: 1;
  height: 100vh;
  width: 80px;
  background: var(--brand-secondary);
  margin: 0;
}

.navigation-bar--open .app-icon {
  display: block;
}

.navigation-bar--open .app-suite-logo {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  margin: 0;
  margin-top: 1rem;
}
.navigation-bar--open .navigation-bar__menu {
  position: absolute;
  top: 8px;
  left: 94px;
}

.navigation-bar--open + .sidebar-filters {
  margin-top: 40px;
}

.navigation-bar__menu {
  cursor: pointer;
}

.app-suite-logo {
  height: 36px;
  width: 36px;
  margin-left: auto;
  text-align: center;
}

.app-suite-logo img {
  max-width: 28px;
  max-height: 28px;
}

.navigation-bar--open .app-suite-logo img {
  max-width: 50px;
  max-height: 50px;
}

.sidebar {
  flex-direction: row;
}

.navigation-bar {
  width: 80px;
  min-width: 80px;
  background: var(--brand-secondary);
  margin: 0;
  height: 100vh;
  flex-direction: column;
}

.navigation-bar__menu {
  display: none;
}

.app-suite-logo {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.app-suite-logo img {
  max-width: 50px;
  max-height: 50px;
}

.app-icon {
  display: block;
}

.sidebar-filters {
  margin-top: 1rem;
  min-width: 260px;
}
