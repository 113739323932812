@import "../../../../typography.css";
@import "../../../../colours.css";

.calendar-view {
  background-color: var(--base-light);
  width: 100%;
  display: flex;
  overflow-x: auto;
}

.calendar-view__loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.booking-calendar {
  position: relative;
}

.booking-calendar .time-slots {
  position: absolute;
  left: 0px;
  top: 0px;
}

.time-slots {
  flex-direction: column;
  position: fixed;
  left: 350px;
  top: 95px;
  z-index: 1;
  background: var(--base-light);
  height: 1920px;
  /* This is the equivalent of height: max-content */
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .time-slots {
    left: 340px;
  }
}

.booking-slots {
  position: relative;
  flex-shrink: 0;
}

.slot {
  height: 40px;
}

.slot:nth-child(odd) {
  border-bottom: 1px dashed var(--base-mid-30);
  border-top: 1px solid var(--base-mid-30);
}

.slot:first-child {
  border-top: none;
}

.slot:last-child {
  border-bottom: 1px solid var(--base-mid);
}

.booked {
  width: 100%;
  cursor: pointer;
  padding: 2px 0px;
}

.bookings {
  width: 100%;
  position: absolute;
  top: 0;
  left: 85px;
  height: 1920px;
  display: flex;
}

.col {
  display: block;
  width: 288px;
  position: relative;
  flex-shrink: 0;
}

.timeslot-info {
  position: relative;
  border-radius: 3px;
  box-sizing: border-box;
  height: 100%;
  margin: 0 38px 2px 12px;
  font: var(--small-type);
  overflow: hidden;
  width: 100%;
}

.booking-info {
  position: relative;
  padding: 8px;
  border-radius: 3px;
  box-sizing: border-box;
  height: 100%;
  margin: 0px 2px;
  font: var(--small-type);
  overflow: hidden;
  background-color: var(--base-white);
  border: 1px solid var(--base-mid);
}

.internal-booking {
  border-color: #006493;
}

.booking-info:hover:not(.booking-info--selected) {
  background: var(--base-mid);
}

.booking-info--overdue-gravity-1:not(.booking-info--selected) {
  background: var(--overdue-yellow);
  color: var(--base-white);
  border-color: var(--overdue-yellow);
}

.booking-info--overdue-gravity-1:hover:not(.booking-info--selected) {
  background: var(--overdue-yellow-darker);
  color: var(--base-white);
  border-color: var(--overdue-yellow-darker);
}

.booking-info--overdue-gravity-2:not(.booking-info--selected) {
  background: var(--overdue-orange);
  color: var(--base-white);
  border-color: var(--overdue-orange);
}

.booking-info--overdue-gravity-2:hover:not(.booking-info--selected) {
  background: var(--overdue-orange-darker);
  color: var(--base-white);
  border-color: var(--overdue-orange-darker);
}

.booking-info--overdue-gravity-3:not(.booking-info--selected) {
  background: var(--overdue-red);
  color: var(--base-white);
  border-color: var(--overdue-red);
}

.booking-info--overdue-gravity-3:hover:not(.booking-info--selected) {
  background: var(--overdue-red-darker);
  color: var(--base-white);
  border-color: var(--overdue-red-darker);
}

.booking-info--selected {
  background: var(--brand-primary);
  color: var(--base-white);
  border-color: var(--brand-primary);
}

.text-header {
  font-weight: bold;
}
