@import "../colours.css";

.app-icon {
  border-radius: 3px;
  margin-top: 0.6rem;
  display: none;
  cursor: pointer;
  border-style: solid;
  border-width: 3px;
  border-radius: 12px;
  border-color: transparent;
  transition : border 300ms ease;
}

.app-icon:hover {
  border-style: solid;
  border-width: 3px;
  border-radius: 12px;
  border-color: var(--base-mid);
}

.app-icon--selected {
  border-style: solid;
  border-width: 3px;
  border-radius: 12px;
  border-color: var(--base-grey);
}

.app-icon img {
  width: 50px;
}

.app-icon__tooltip {
  color: var(--base-white);
  position: absolute;
  left: 80px;
  height: 50px;
  padding: 0.5rem;
  background: var(--base-black);
  z-index: 5;
  border-radius: 9px;
  opacity: 0;
  pointer-events: none;
  transition: left 0.3s ease, opacity 0.3s ease;
}

.app-icon:hover .app-icon__tooltip {
  opacity: 1;
  left: 72px;
}
