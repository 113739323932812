@import "../typography.css";
@import "../colours.css";

.admin-item {
  height: 74px;
  position: relative;
  width: 298px;
  border-top: 1px solid var(--base-grey);
  padding: 8px 16px;
  cursor: pointer;
  border-left: 8px solid var(--brand-tertiary);
}

.admin-item--selected {
  border-left: 8px solid var(--brand-primary);
}

.admin-item:last-child {
  border-bottom: 1px solid var(--base-grey);
}

.admin-item-title {
  font: var(--content-type);
}

.admin-item-text-row {
  display: flex;
  justify-content: space-between;
}

.admin-item-text {
  font: var(--small-type);
}

.admin-item-right-text {
  font: var(--small-type);
  align-self:flex-end;
}

.admin-item-icon {
  position: absolute;
  right: 16px;
  top: 20px;
}
